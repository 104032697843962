.mainSec{
	display: table;
	width: 100%;
	height: 100%;
}
.headerControl{
	display: table-row;
	background-color: #59636c;
    padding: 10px;
    height: 0.1px;
}.areaList{
	/*display: table-cell;*/
}

 h3 {
	color: #a9a9a9;
    padding: 0px;
    margin: 0px;
    font-size: 15px;
} h3 small {
    padding: 0px 10px;
    color: #1f2326;
}


.mainScrollY{
	/*position: absolute;*/
    height: 100%;
    overflow: auto;
    width: 100%;
}


.listmodels{
    margin: 0px;
    padding: 0px;
}.listmodels li{
    list-style: none;
    padding: 20px;
    background-color: #59646e;
    /*border: 1px solid #919ba5;*/
    cursor: pointer;
    border:1px solid #59646e;
}.listmodels li *{
    cursor: pointer;
}.listmodels li.active{
    background-color: #636f7a;
    border:1px solid #ff7a00;
}.listmodels li:hover{
    background-color: #636f7a;
}.listmodels li:active{
    background-color: #2f3336;
}.listmodels li label{
    color: #919ba5;
    font-size: 16px;
    font-weight: bold;
}.listmodels li p{
    color: #384048;
    font-size: 13px;
    font-weight: normal;
    margin: 0px;
}