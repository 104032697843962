.notifications{
}.notifications li {
	padding:0px 0px !important;
}.notifications li .layoutcard{
	/* border-bottom: 1px solid #0000001f; */
}.notifications li .layoutcard {
	margin:0px;
}.notifications li .layoutcard .sideLi{
	white-space: nowrap;
}

.notifications p {
    color: #878787;
}
.notifications .header .button {
    border-radius: 100px;
}