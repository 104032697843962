
/*.-btnoptions{
    color: #7e7e7eed;
    background-color: #f7f7f717;
    border: 0px;
    border-color: #f7f7f7;
}
*/



.options.noscroll .options_area .options_area_top{
    overflow: hidden !important;
}.options.noscroll ::-webkit-scrollbar{
    width: 0px !important;
    height: 0px !important;
}
.options.noscroll ::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 0 5px var(--scroll-bg);
}
.options.noscroll ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 0 5px var(--scroll-fg);
}
.options.noscroll :hover::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 0 5px var(--scroll-fg-hover);
}


.options{
	position: fixed;
    top: 0px;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 100000;

    /*transition: 600ms;*/

}.options.active{
	opacity: 1;	   
}
.options .options_bg{
	position: fixed;
    top: -20px;
    left: -20%;
    width: 120vw;
    height: 120vh;
    /*transition: 300ms;*/
}.options .options_area{
	position: absolute;
    top: 0px;
    left: 0%;
    width: 95%;
    /*transform: translateX(-50%);*/
    /*transition: 600ms;*/
    /*transition-timing-function: ease-in-out;*/
    display: table;
    
}.options .options_area .options_area_top{
    display: table-row;
    /*max-height: 80vh;*/
    /*overflow-y: auto;*/
    
}.options .options_area .options_area_bottom{
	color: #fff;
    display: table-row;
    height: 0.1px;
}.options .options_area .options_area_bottom > div{
    
}.options .options_area .options_area_bottom button{
	font-size: 25px;
}



.options *{
    scroll-behavior: smooth;
}
.options ::-webkit-scrollbar{
    width: 0px;
}
.options ::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 0 15px var(--scroll-bg);
}
.options ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 0 15px var(--scroll-fg);
}
/**:hover::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 0 15px var(--scroll-fg-hover);
}*/


/* Style */

/*.options .options_area .options_area_top{
    border-radius: 0px 0px 5px 5px;
}
*/

.options .options_title {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    color: #707070;
    display: inline-table;
}
/*.options .options_header {
    background-color: #f9f9f9;
    border-bottom: 1px dashed #d5d5d5;
}
*/

.options:not(.active) .options_area .options_area_bottom {
    /*opacity: 0;*/
    /*transition: 400ms;*/
}.options .options_area .options_area_bottom {
    /*opacity: 1;*/
    position: absolute;
    width: 100%;
}


.options.active{
    /*transition: 0ms;*/
    /*transform: translateX(0%);*/
}.options:not(.active){
    /*transition: 1000ms;*/
    /*transform: translateX(-100%);*/
    /*opacity: 0 !important;*/
    pointer-events: none;
}.options:not(.active) .options_bg{
    /*opacity: 0;*/
    pointer-events: none;
}.options:not(.active) .options_area{
    /*transform: translateX(-50%) translateY(-100%);*/
}

.options{
    /*border: 5px solid red;*/
    transform: translateY(-300%);
    opacity: 0;
    transition: 600ms !important; 
}.options.active{
    transition: 600ms !important; 
    /*transition: 1000ms; */
    /*transition: 0ms; */
    /*opacity: 1 !important; */
    /*transform: translateY(-25%);*/
    /*transform: translateY(-100%);*/
}.options .options_area{        
    /*transform: translateX(-50%) ;*/
    /*transform: translateX(-50%) translateY(-100%);*/
}.options.active .options_area{        
    /*transform: translateX(-50%) translateY(-50%);*/
}

/*body [id="root"]{
    transition: 400ms;
}*/