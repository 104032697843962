.page.home .header {
	position: absolute;
    height: 90px;
    z-index: 10000;
    transition: 0ms;
}.page.home .header::before {
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(180deg,#434c54 32%,transparent 100%);
}.page.home .main {
	padding-top: 90px;

}.page.home .avatar-wapper{
	/*background-color: #363a3e;*/
    margin: 0px;
    /*padding: 10px 5px 0px;*/
}.page.home .avatar-wapper .avatar-image{
	width: 60px;
    height: 100%;
    padding: 0px;
    margin: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    display: block;


        /*width: 70%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    object-position: center;
    filter: grayscale(1);*/

}.page.home .avatar-wapper .avatar-name{
	color: #c5c5c5;
    display: block;
    font-size: 15px;
    font-weight: bold;
}.page.home .avatar-wapper .avatar-email{
	color: #ffffff47;
    display: block;
    font-size: 14px;
}.page.home .avatar-wapper .avatar-tools{
    margin: 0px;
    padding: 0px;
    display: table;
    /*border-spacing: 5px;*/
    /*width: 100%;*/
}.page.home .avatar-wapper .avatar-tools li{
    display: table-cell;
    list-style: none;
}.page.home .avatar-wapper .avatar-tools li a{
    display: block;
    width: 16px;
    height: 16px;
    margin: 5px 5px 0px 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}.page.home .avatar-wapper .avatar-tools li.telegram a{
    background-image: url(../../../assets/img/telegram-logo.png);
}.page.home .btn{
    margin-bottom: 10px;
    border-radius: 5px;
    background: linear-gradient(90deg, transparent 22%, transparent 10%);
}.page.home .btn small{
    color: #cfcfcf;

}.page.home .btn.contrast{
        border: 1px solid #383d42;
    
}.page.home .btn.nocontrast{
    border:0px;
}.page.home .btn.nocontrast .icon{
    
    background-color: #252a30;
    border-radius: 5px;
    padding: 12px 0px;
    color: #8b98a9;
}.page.home .btn.nocontrast strong{
    color: #000;
    font-weight: normal;
    font-size: 13px;
}.page.home .btn.nocontrast small{
    font-size: 21px;
    font-weight: bold;
    color: #98a5b1 !important;
}

.page.home .btn.contrast strong{
    color: #ffffffb8;
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 5px;
}.page.home .btn.contrast small{
    font-size: 21px;
    font-weight: bold;

}

.page.home .balancelist{
    margin: 0px;
    padding: 0px;
    border-radius: 5px;
    background-color: #252a30;
}.page.home .balancelist > li {
    list-style: none;
    width: 100%;
    display: inline-table;
    text-align: right;
    padding: 0px 10px;
}.page.home .balancelist > li:not(:last-child) {
    border-bottom: 1px dashed #3d454d;


}.page.home .balancelist > li > div{
    /*border-radius: 5px;
    background-color: #252a30;*/
    padding: 10px 0px;
    /*margin-bottom:  10px;*/
}.page.home .balancelist > li label{
    font-weight: bold;
    color: #aaa;
    font-size: 14px;

}.page.home .balancelist > li cite{
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    margin: 0px 0px 0px 5px;
    color: #596066;
}.page.home .balancelist > li span{
    font-size: 13px;
    color: #8f979f;
}.page.home .balancelist > li ul{
    margin: 0px;
    padding: 0px;
}.page.home .balancelist > li ul li{
    list-style: none;
    text-align: right;
}.page.home .balancelist > li ul li.active {
    color: #ffc107;
}.page.home .balancelist > li ul li.active span,
.page.home .balancelist > li ul li.active cite {
    color: #ffc107;
}.page.home .balancelist > li ul li.division {
    /*border-top: 1px dashed #505964;*/
    padding-top: 5px;
    margin-top: 5px;
}.page.home .balancelist > li ul li.negative span{
    color: #dc3545;
}.page.home .balancelist > li ul li.positive span {
    color: #15c73f;
}

/*.page.home.menuAvatar-active .header{
 
}*/
.page.home .menuAvatar{
    margin: 10px 20px;
    border-top: 1px solid #34383c7a;
    z-index: 100000;
    position: sticky;
}.page.home .menuAvatar ul{
    padding: 0px;
    margin: 0px;
}.page.home .menuAvatar ul li {
    list-style: none;
}.page.home .menuAvatar ul li a{
    display: block;
    padding: 10px;
}.page.home .menuAvatar ul li svg{
    width: 24px;
}.page.home.menuAvatar-active .header::before{
       height: 100vh;
/*}.page.home.menuAvatar-active  .header::before { */
    /*background: linear-gradient(180deg,#434c54 32%,#434c54d1 100%);*/
    background: transparent;
    backdrop-filter: blur(63px);
    z-index: 1000;
    pointer-events: auto;
}.page.home.menuAvatar-active .avatar-wapper{
    z-index: 1001;

}