table{
	width: 100%;
}table tr{
}table tr th{
	width: 20%;
    background-color: #3a4248;
    padding: 5px 10px;
    color: #7b8893;
}table tr td{
    padding: 5px 10px;
    color: #7b8893;

}