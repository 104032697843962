

.--admin{
}.--admin .order{
}.--admin .order.buy{
	color: #00d300;
}.--admin .order.sell{
	color: red;
}.--admin .order.and{
	color: #00000091;
}.--admin .order.or{
	color: #00000091;
}/*.--admin .options_header .-third{
	background-color: #434c54 !important;
	border-color: #434c54 !important;
	color: #fff !important; 
	box-shadow: none !important;
}.--admin .options_header .-third:hover{
	background-color: #48535c !important;
	border-color: #48535c !important;
	color: #fff !important; 
}.--admin .options_header .-third:active{
	background-color: #242a2e !important;
	border-color: #242a2e !important;
	color: #fff !important; 
}

*/





.ulist{
	position: relative;
}.ulist ul{
	position: absolute;
        right: 30px;
    bottom: 0%;
    background-color: #4a555e;
    width: max-content;
    display: block;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    border-radius: 4px;
    box-shadow: 0px 0px 14px -7px #2d3338;
}.ulist:hover ul{
	pointer-events: auto;
	opacity: 1;
}.ulist ul li{
	list-style: none;
	text-align: left;
}.ulist ul li a{
	display: block;
	padding: 10px;
	color: #778793 !important;
	text-decoration: none;
}.ulist ul li:hover a{
	text-decoration: none;
	color: #8097a9 !important;
}.ulist ul li  svg{
	width: 25px;
}.ulist ul li  span{
}

.icon-enabled{
}.icon-enabled.enabled{
	color: #1cb841;
}.icon-enabled.disabled{
	color: #202428;

}


.separateorder{
    text-align: center;
    height: 0px;
    position: relative;
    margin-bottom: -10px;
    padding-top: 50px;
}.separateorder label{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateY(-50%) translateX(-50%);
	background-color: #788591;
	color: #21272c;
    padding: 2px ;
    border-radius: 3px;
    margin: 0px;
	font-size: 10px;
	z-index: 10;
}.separateorder .line{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateY(-50%) translateX(-50%);
    padding: 0px ;
    margin: 0px;
	border-bottom: 1px dashed #788591;
	z-index: 0;
	width: 30%;
}

