.page.bots .backteststatus-loss{
	color: red !important;
}.page.bots .backteststatus-lossfull{
	color: red !important;
}.page.bots .backteststatus-gain{
	color: #22d14c !important;
}.page.bots .backteststatus-error{
	color: #dfdfdf !important;
	/*transform: scale(1,-1);*/
}.page.bots button.backteststatus-error::before {
    content: "*";
    color: red;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 27px;    
}.page.bots button.backteststatus-error small {
	display: none;
}.page.bots .backteststatus-notest{
	color: #28282845 !important;
}.page.bots .backteststatus-reset{
	color: #28282845 !important;
}.page.bots .backteststatus small{
	    font-size: 11px;
}

.page.bots .coin_last_price{
	font-size: 14px;
	color: #ffffff73 !important;
}


.page.bots .backtestcount{
	white-space: nowrap;
}.page.bots .backtestcount .gain{
	/*padding-left: 5px;*/
    color: #20b743;
    font-size: 11px;
}.page.bots .backtestcount .loss{
    padding-left: 5px;
    color: #ff3838;
    font-size: 11px;
}



/*[route="/dashboard/bots"] .listCards .layoutcard .title,
[route="/dashboard/bots"] .listCards .layoutcard .description{
	    
    margin: auto 0 0 0 ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 145px;
    display: block;
}*/

.page.bots .listCards .layoutcard[enabled="false"] > div{
    opacity: 0.7 !important;
}.page.bots .listCards .layoutcard[enabled="true"] .icon{
    color: #1cb841;
}.page.bots .listCards .layoutcard[enabled="false"] .icon{
    color: #31363a;
}

.page.bots .listCards .layoutcard[enabled="false"] ~ *{
    opacity: 0.4 !important;

}
.page.bots .listCards .layoutcard[enabled="false"] .progresssbarline{
    display: none !important;

}

.page.bots .listCards .coin{
    font-size: 10px;
}

.page.bots .progressbar {
    background-color: #303233;
}.page.bots .progressbar.buy .wrap span{
    background-color: #00d123;
}.page.bots .progressbar.or .wrap span{
    background-color: #96a4b1;
}.page.bots .progressbar.sell .wrap span{
    background-color: red;

}

/*.progressbar.buy {
    background-color: #373b3e;
}*/

.page.bots .listCards > ul > li{
    padding: 10px 0px 10px  !important;
}/*.page.bots .listCards > ul > li:hover{
    background-color: #4c5863;
}*/

.page.bots .progresssbarline{    
}