.headerBar  {
	padding: 0px 10px;
    background-color: #222;
    position: relative;
}.headerBar .headerBarArea {
	/*background-color: #202020;*/
    border-radius: 300px;
}.headerBar .iconmaster {
	display: table;
	padding: 10px 0px;
}.headerBar .iconmaster img{
	display: table-cell;
	vertical-align: middle;
	filter: grayscale(1);
}.headerBar .iconmaster label{
	display: table-cell;
    vertical-align: middle;
    color: #a1a1a1;
    padding: 0px 5px;
    font-size: 13px;
    font-weight: normal;
}.headerBar .logo{
	width: 32px;
}.headerBar a{
	color: #fff;
}