

.page.orders .index{
	color: #00000080;
}.page.orders .target_status{
	color: #e9e9e9f2;
}.page.orders .target_status .miniicon{
	padding: 0px 5px 0px 0px;
}

/*.page.orders .target_status.gain .miniicon{
	color: #1cb841;	
}.page.orders .target_status.loss .miniicon{
	color: #ff0000;
}*/

.page.orders .target_status.sell .miniicon{
	transform: scale(1,-1);
}.page.orders .target_status .target_status_percent{
	font-size: 11px;
    color: #ffffff38;
    padding: 0px 0px 0px 5px;
}.page.orders .createtime{
	color: #9b9b9bfa;
    font-size: 11px;
    margin-left: 19px;
}.page.orders .order_main_simbol{
	color: #9b9b9bfa;
    font-size: 11px;
    margin-left: 19px;
    display: block;
}.page.orders .coin_last_price{
    color: #9b9b9bfa;
    font-size: 11px;
    margin-left: 19px;
    display: block;
}.page.orders .order_main_pln{
    color: #9b9b9bfa;
    font-size: 11px;
    margin-left: 19px;
    display: block;
}.page.orders .order_main_pln cite{
    color:#222;
}.page.orders .botname{
	color: #9b9b9bfa;
    font-size: 11px;
    margin: 0 0 0 auto;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70px;
    display: block;
}.page.orders .barButtons {
    background-color: #30363a;
}.page.orders .barButtons .button{
    /*background-color: #3b444a !important;*/
}.page.orders .barMenu {
    min-height: 58px;
}.page.orders .barControl .button{
    border-radius: 5px !important;
}.page.orders .barControl .button strong{
    font-weight: normal;
    font-size: 13px;
    color: #2a2f34;
}.page.orders .barControl .button small{
    font-weight: bold;
    font-size: 14px;
/*}.page.orders .listCards ul li:not(.active){*/
    /*opacity: 0.5;*/

}.page.orders .listCards > ul > li > ul > li{
    display: block;
    margin-bottom: 20px;
}.page.orders .listCards > ul > li{
    /*margin-bottom: 30px;*/
    display: block;
    width: 100%;
    border-bottom: none !important;
}.page.orders .listCards ul li .exchange_title{
    padding: 10px 20px;
    font-weight: bold;
    font-size: 15px;
    color: #272c30;
    /*border-top: 1px dashed #272c30;*/
    width: 100%;
    display: block;
}.page.orders .listCards ul li.finish{
    opacity: 0.6;
    /*filter: blur(1px);*/
}.page.orders .listCards ul li .order_status{
    display: inline-table;
    width: 0px;
    height: 0px;
    padding: 5px;
    border-radius: 20px;
    margin: 7px 0px 0px 7px;
    background-color: #222;
}.page.orders .listCards ul li.active .order_status{
    background-color: #00f33a;

}.page.orders .listCards ul li .number{
    font-size: 12px;
}




.page.orders .listCards ul li.order .progressbar {
     background-color: #00000014 !important;
}.page.orders .listCards ul li.order .progressbar .wrap span{
    /*display: none;*/
    background-color: transparent !important;
    border-right: 3px solid #00d123;

}.page.orders .listCards ul li.equal .progressbar{
    background-color: #2a2f32 !important;
}.page.orders .listCards ul li.equal .progressbar .wrap span{
    background-color: transparent !important;
}


.page.orders .listCards ul li.loss .progressbar {
     background-color: #ff0000f7 !important;
}.page.orders .listCards ul li.loss .progressbar .wrap span{
    display: none;
}
.page.orders .listCards ul li.gain .progressbar {
     background-color: #00d123 !important;
}.page.orders .listCards ul li.gain .progressbar .wrap span{
    display: none;
}


/*.page.orders .listCards ul li.active .progressbar[status="gain"] .wrap span{
    background-color: #0ef93669;
}.page.orders .listCards ul li.active .progressbar[status="loss"] .wrap span{
    background-color: #ff000096;
}*/


.page.orders .listCards ul li.positioned  {
    opacity: 1;
}.page.orders .listCards ul li.finish  .order_status{
    background-color: #00000029;
}.page.orders .listCards ul li.positioned  .order_status{
    background-color: #10e542;
}.page.orders .listCards ul li.order  .order_status{
    background-color: #ffc107;
}.page.orders .listCards ul li.cancel  .order_status{
    background-color: #ff000096;
}.page.orders .listCards ul li.finish-gain  .order_status{
    background-color: #72818f69;
}.page.orders .listCards ul li.finish-loss  .order_status{
    background-color: #ff000096;

}.page.orders .listCards ul li.order.action-cancel  .order_status,
.page.orders .listCards ul li.positioned.action-cancel  .order_status{

    background-color: transparent !important;
    display: inline-block;
    color: #dc3545;
    margin: 0px;

}.page.orders .listCards ul li.finish.action-canceled  .order_status{
    background-color: transparent !important;
    display: inline-block;
    color: #dc3545;
    margin: 0px;
}


.page.orders .barControl {
    background-color: #272c30;
}.page.orders .main {
    background-color: #394148;
}.page.orders .tabwrapper {
    background-color: #394148;
    padding: 10px 10px  !important;
    border-bottom: 1px dashed #333;
}.page.orders .btn label{
    color: #85aac3;
    font-weight: normal;
    font-size: 14px;
}.page.orders .btn small{
    color: #9f9f9f;
}




.loading-position::before{
    border-bottom-color: transparent!important;
    position: absolute !important;
    padding: 4px;
    left: 19px;
    border-width: 2px;
}