.questioninline{
	display: inline-table;
}

[question]{
	display: inline-table;
	width: 40px;
    text-align: center;
    white-space: nowrap;
}

[alternatives]:not(.active){
	display: none;
}

[response]{
	display: inline-table;
	width: 40px;
    text-align: center;
}