.mainSec{
	display: table;
	width: 100%;
	height: 100%;
}
.headerControl{
	display: table-row;
	background-color: #59636c;
    padding: 10px;
    height: 0.1px;
}.areaList{
	/*display: table-cell;*/
}

 h3 {
	color: #a9a9a9;
    padding: 0px;
    margin: 0px;
    font-size: 15px;
} h3 small {
    padding: 0px 10px;
    color: #1f2326;
}


.mainScrollY{
	/*position: absolute;*/
    height: 100%;
    overflow: auto;
    width: 100%;
}

/*.boxItemBacktest.balanceloss h3{
    color: #dc3545;

}.boxItemBacktest h3 small{
    padding: 0px 10px;
    color: #1f2326;

}.boxItemBacktest.balanceloss .tableBacktest{
    background-color: #dc3545;
    border: 1px solid #dc3545;
}.boxItemBacktest.balanceloss [infobox]{
    color: #dc3545;
}*/

.boxItemBacktest h3 {
    padding: 10px;
}


.tableBacktest {
	width: 100%;
    border: 1px solid #33393e;
    /*box-shadow: 0px 0px 20px 0px #eee;*/
    border-spacing: 0px;
}.tableBacktest tr th {
    background-color: #3b4248 !important;
    vertical-align: top;
    padding: 5px !important;
    color: #aaa;
    font-weight: normal;
    text-align: right;
    font-size: 13px;
}.tableBacktest td{
    color: #666;
    padding: 0px 5px;
}.balanceloss .tableBacktest td{
    color: #f1d7d7 !important;
}.tableBacktest tr{
	    background-color: #303538;
}.tableBacktest .fee{
    color: #888;
    margin-left: 5px;
}.balanceloss .tableBacktest .fee{
    color: #ffc5c5;
}

.boxItemBacktest.gain{
    /*border: 1px solid #1cb841;*/
}.boxItemBacktest.gain [statustd]{
    background-color: #1cb841;
    color: #fff;
    text-transform: capitalize;
}

.boxItemBacktest.loss{
    /*border: 1px solid red;*/
}.boxItemBacktest.loss [statustd]{
    background-color: red;
    color: #fff;
    text-transform: capitalize;
}


.tableBacktestTotal{
}.tableBacktestTotal.balanceloss #backtest_balance{
    color:#dc3545;
}.tableBacktestTotal.balanceloss #backtest_test_balance_estimate1{
    color:#dc3545;
}.tableBacktestTotal.balanceloss #backtest_balanceaccumulated{
    color:#dc3545;
}.tableBacktestTotal.balanceloss #backtest_margin{


}.tableBacktestTotal [signal="negative"]{
    background-color: red !important;
    color: #fff !important;
}.tableBacktestTotal [signal="positive"]{
    background-color: green !important;
    color: #fff !important;

}.tableBacktestTotal .button strong{
    font-size: 12px;
    color: #373b3e;
}.tableBacktestTotal .button span{
    font-size: 14px;
}


.alerttest{
    background-color: #ffcd38;
    border-radius: 3px;
    padding: 10px;
}.alerttest p{
    margin:0px 0px 10px 0px;
    padding:0px;
    font-size: 14px;
}