/*.date button{
	font-size: 14px;
}*/

.main-option .order_status{
	color: #7a8895;
    font-size: 14px;
}.main-option .order_status::before{
	content: " ";
    display: inline-table;
    width: 0px;
    height: 0px;
    padding: 5px;
    border-radius: 20px;
    margin: 0px 5px 0px 5px;
    background-color: #32383c;
    vertical-align: middle;
}.main-option.active .order_status::before{
    background-color: #00f33a;

}

.main-option .button strong{
    font-size: 12px;
    /*color: #34373a;*/
}.main-option .button small{
	font-size: 12px;
}


.main-option .roi_porcent{
	font-size: 11px;
    margin: 0px;
    font-weight: normal;
    display: inline-table;
    background-color: #1111114f;
    padding: 2px;
    color: #ffffffc9;
}

.main-option.order  .progressbar {
    background-color: #00000014 !important;

}.main-option.order  .progressbar .wrap span{
    /*display: none;*/
    background-color: transparent !important;
    border-right: 3px solid #00d123;

}.main-option.equal  .progressbar {
    background-color: #3a4046 !important;
}.main-option.equal  .progressbar .wrap span{
    background-color: transparent !important;
}/*.main-option .progressbar::before{
    content: " ";
    position: absolute;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: #343434;*/


.main-option.gain .progressbar {
    background-color: #00d123;
}.main-option.loss .progressbar {
    background-color: #ff0000f7;
}.main-option.gain .progressbar .wrap span{
    display: none;
}.main-option.loss .progressbar .wrap span{
    display: none;

}


.main-option.cancel .progressbar .wrap span{
    background-color: transparent;
}.main-option.cancel .order_status::before{
    background-color: #ff000096;

}.main-option.positioned .order_status::before{
    background-color: #10e542;
}.main-option.order .order_status::before{
    background-color: #ffc107;
}.main-option.finish .order_status::before{
    background-color: #00000029;
}

.main-option.finish-gain .order_status::before{
    background-color: #0ef93669;
}.main-option.finish-loss .order_status::before{
    background-color: #ff000096;

}.main-option.order.action-cancel  .order_status,
.main-option.positioned.action-cancel  .order_status{
    background-color: transparent !important;
    display: inline-block;
    color: #ff6170;
    margin: 0px 5px 0px 5px;
}.main-option.order.action-cancel  .order_status::before,
.main-option.positioned.action-cancel  .order_status::before{
    display: none;
}

/*.main-option.finish.action-canceled  .order_status{
    background-color: transparent !important;
    display: inline-block;
    color: #ff6170;
    margin: 0px 5px 0px 5px;
}*/.main-option.finish.action-canceled  .order_status::before{
    display: none;
}


.main-option .statusfinish.gain .button{
    background-color: #0ef93669;
    color: #fff;
    border-color: transparent;
}.main-option .statusfinish.loss .button{
    background-color: #ff000096;
    color: #fff;
    border-color: transparent;
}