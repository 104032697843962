.tabswrapper{
    display: block;
    overflow: hidden;
    width: 90vw !important;
}

.tabs{
    display: block;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
}.tabs ul{
    margin: 0px;
    padding: 0px;
    display: table;
    width: max-content;
    /*position: absolute;*/
}.tabs ul li{
    display: table-cell;
}.tabs ul li a{
    display: block;
    padding: 10px 20px ;
    color: #6e787e !important;
    text-decoration: none !important;
    /* font-weight: bold;*/
    transition: 400ms;
}.tabs ul li:hover a{
    /*color: #717678 !important;*/
}.tabs ul li.active a{
    color: #bdbdbd !important;
    /* border-bottom: 3px solid #303538; */
    background-color: #4c565e !important;
    border-radius: 30px;
}.tabs::-webkit-scrollbar {
    /*width: 5px !important;*/
    height: 0px !important;
}
.tabs::-webkit-scrollbar-thumb {
    border-radius: 50px !important;
}
