.layout_dashboard_menumobile .logo{
	    width: 30px;
}

.MenuMobile {
    margin: 10px 0px 10px;
    background-color: #ff7a00;
    border-radius: 10px;
    /*border-radius: 100px;*/
}.MenuMobile ul li{
    position: relative;
    pointer-events: none;
}.MenuMobile ul li a {
    pointer-events: auto;
	transform: translateX(-50%) translateY(-50%) scale(1);	
    padding: 0px !important;
    height: 100%;
    width: 100%;
    color: #854205 !important;
    width: 33px;
    padding: 0px;
    border-radius: 100px;
    display: table;
}.MenuMobile ul li.active a {
    transform: translateX(-50%) translateY(-50%) scale(1.2);    
   
    /*color: #333 !important;*/
    /*box-shadow: 0px 0px 0px 12px #2d333a;*/
    /*box-shadow: 0px 0px 0px 12px #22272d;*/
}.MenuMobile ul li a svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 20px 8px;
}.MenuMobile ul li.active a svg{
    /*box-shadow: 0px 0px 0px 8px #22272d;
    border-radius: 30px;
    padding: 12px 12px;*/

    box-shadow: 0px 0px 0px 0px #22272d;
    border-radius: 0px;
    padding: 22px 8px;
    /*padding: 12px 12px;*/
    background-color: #4c4f52;
    color: #b5b5b5;
    border-radius: 4px;

}.MenuMobile ul li .badge{
    z-index: 10000000;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%) scale(0.8);
    background-color: #606060c4;
    border-radius: 3px;
    border: 0px solid #ff7a00;
}.MenuMobile ul li .badge:not([number]){
    display: none;
}.MenuMobile ul li .badge[number="0"]{
    display: none;

}