.avatar-area{
	/*background-color: #292f34;*/
}.avatar-image{
	width: 100px;
    border-radius: 100px;
    margin-top: 10px;
}.avatar-image{
	/*width: 0px;
    height: 0px;
    padding: 32px;
    margin: 5px 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;*/

    width: 60px;
    height: 60px;
    padding: 0px;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    display: block;

    
    /*width: 20%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    object-position: center;
    filter: grayscale(1);*/

}