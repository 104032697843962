.LocalStrategy ul{
	padding: 0px;
	margin: 0px;
}.LocalStrategy ul li{
	list-style: none;
	border-bottom: 1px dashed #30363c94;
}.LocalStrategy ul li:hover{
	background-color: #4b555e !important;
}.LocalStrategy ul li a{
	display: block;
	padding: 10px;
	text-decoration: none !important;
	color: #7b8b99 !important;
}.LocalStrategy ul li label{
	display: block;
	margin: 0px;
	color: #8d9aa7 !important;
}.LocalStrategy ul li small{
	font-size: 11px;
    color: #2d363c;    
}.LocalStrategy ul li:hover a{
	color: #7b8b99 !important;
}.LocalStrategy ul li.active {
	background-color: #526372 !important;
	color: #fff !important;
	border-radius: 2px;
}.LocalStrategy ul li .link{
	color: #30353a !important;
}.LocalStrategy ul li .link:hover{
	color: #ff7a00 !important;
}.LocalStrategy ul li .description{
	display: none;
	padding: 0px;
    margin: 0px;
}.LocalStrategy ul li.active .description{
	display: inline-block;
    padding: 10px 0px;
    margin: 0px;
    font-size: 11px;
    color: #353535;
}


.btnuse{
	border: 1px solid #394046 !important;
    color: #272a2e !important;
}.btnuse.active{
	background-color: #4e5962 !important;
    color: #fff !important;
}.btnuse small{
	color: #272c30;
}