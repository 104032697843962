.wrapperRuleField{
	background-color: #4d555c;
    margin: 5px 10px 10px 10px;
    padding-bottom: 10px;
    border: 1px dashed #30363c;
    border-radius: 5px;
}.wrapperRuleField2{
	background-color: #4d555c;
    margin: 5px 10px 10px 10px;
    padding-bottom: 10px;
    border: 1px dashed #30363c;
    border-radius: 5px;
}

.bg_a{
    background-color: transparent;
}.bg_b{
    background-color: #4d555c;
}

.fieldconfig{
    padding: 10px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    border-top: 1px dashed #333;
}.fieldconfig li{
    list-style: none;
}

.fieldconfig2{
    padding: 10px 0px 0px 0px;
    margin: 10px 0px 0px 0px;
    border-top: 1px dashed #333;
}.fieldconfig2 li{
    list-style: none;
}