.--splash{

}.--splash {	
    background-color: #434c54;
    /*background-image: url(background.jpg);*/
	background-size: cover;	
	position: relative;	
    background-position: top;

}.--splash::before {

	content: " ";
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	/*background: linear-gradient(0deg, #323232 40%, transparent 120%);*/
    /*background: linear-gradient(0deg, #ff7a008a 50%, transparent 80%);*/
	/*backdrop-filter: hue-rotate(85deg);*/
}.--splash .logo{
        filter: saturate(0) brightness(0.3);
}.--splash h1{
    font-size: 20px;
    color: #2f353a;
    margin: 5px 0px 0px 0px;
    font-weight: bold;
}


.--splash{

    --background:#fff;
    --background-second:#eee;
    --color:#555;
    

    --page-header-background:#f3f3f3;
    --page-header-color:#666;

    --page-footer-background:#d0d0d0;
    --page-footer-color:#444;

    --scroll-bg: #eee;
    --scroll-fg: #043F94;
    --scroll-fg-hover: #043F94;

    --default: #dedede;
    --default-hover: #d4d4d4;
    --default-active: #a7a7a7;
    --default-focus: #a7a7a7;
    --default-clean: #bbbbbb;
    --default-border: #dedede;
    --default-border-hover: #ddd;
    --default-border-active: #888;
    --default-border-focus: #fff;
    --default-text: #555;
    --default-text-hover: #555;
    --default-text-active: #555;
    --default-text-focus: #555;

    --primary: #ff7a00;
    --primary-hover: #fd9638;
    --primary-active: #ad5402;
    --primary-focus: #084db1;
    --primary-border: #ff7a00;
    --primary-border-hover: #fd9638;
    --primary-border-active: #ad5402;
    --primary-border-focus: #084db1;
    --primary-text: #fff;
    --primary-text-hover: #fff;
    --primary-text-active: #fff;
    --primary-text-focus: #fff;

    --secondary: #1b1c1d;
    --secondary-hover: #27292a;
    --secondary-active: #111;    
    --secondary-focus: #42484c;
    --secondary-border: #1b1c1d;
    --secondary-border-hover: #27292a;
    --secondary-border-active: #111;
    --secondary-border-focus: #42484c;
    --secondary-text: #aaa;
    --secondary-text-hover: #ccc;
    --secondary-text-active: #666;
    --secondary-text-focus: #666;

    --success: #1cb841;
    --success-hover: #1DA73E;
    --success-active: #1C9438;
    --success-focus: #1cb841;
    --success-border: #888;
    --success-border-hover: #1DA73E;
    --success-border-active: #1C9438;
    --success-border-focus: #1cb841;
    --success-text: #fff;
    --success-text-hover: #fff;
    --success-text-active: #fff;
    --success-text-focus: #fff;

    --error: #dc3545;
    --error-hover: #C82030;
    --error-active: #B3101F;
    --error-focus: #B3101F;
    --error-border: #dc3545;
    --error-border-hover: #dc3545;
    --error-border-active: #dc3545;
    --error-border-focus: #dc3545;
    --error-text: #fff;
    --error-text-hover: #fff;
    --error-text-active: #fff;
    --error-text-focus: #fff;

    --warning: #ffc107;
    --warning-hover: #E9B109;
    --warning-active: #D09D05;
    --warning-focus: #D09D05;
    --warning-border: #ffc107;
    --warning-border-hover: #E9B109;
    --warning-border-active: #D09D05;
    --warning-border-focus: #D09D05;
    --warning-text: #fff;
    --warning-text-hover: #fff;
    --warning-text-active: #fff;
    --warning-text-focus: #fff;

    --border: transparent;
    --border-hover: transparent;
    --border-active: transparent;
    --border-focus: transparent;
    --border-border: #e0e0e0;
    --border-border-hover: #bdbdbd;
    --border-border-active: #929292;
    --border-border-focus: #929292;
    --border-text: #656565;
    --border-text-hover: #656565;
    --border-text-active: #444;
    --border-text-focus: #444;    


    --link-default: #ff7a00;
    --link-default-hover: #ffa757;
    --link-default-active: #8d4300;    
    --link-default-focus: #ff7a00;

    --link-primary: #3b76ca;
    --link-primary-hover: #4a93fb;
    --link-primary-active: #084db1;    
    --link-primary-focus: #084db1;

    --link-secondary: #6b6b6b;
    --link-secondary-hover: #989898;
    --link-secondary-active: #42484c;    
    --link-secondary-focus: #42484c;

    --link-success: #1cb841;
    --link-success-hover: #16E547;
    --link-success-active: #1c9438;    
    --link-success-focus: #1c9438;

    --link-error: #dc3545;
    --link-error-hover: #f76876;
    --link-error-active: #791e27;    
    --link-error-focus: #791e27;

    --link-warning: #cc9c0c;
    --link-warning-hover: #eab619;
    --link-warning-active: #6b530d;    
    --link-warning-focus: #6b530d;

    --link-invert: #e2e2e2;
    --link-invert-hover: #c5c5c5;
    --link-invert-active: #a7a7a7;    
    --link-invert-focus: #a7a7a7;

    --input-background:#f5f5f5;
    --input-color:#fff;
    --input-border-color:#222;
    --input-highlight-color:#ff7a00;
    --input-placeholder-color:#888;
    --input-placeholder-color-focus:#ff7a00;

    --input-checkbox-background:#f5f5f5;    
    --input-checkbox-foreground-checked:#0d6efd;

    --input-radio-background:#f5f5f5;    
    --input-radio-foreground-checked:#0d6efd;


    --table-header-background:#eee;
    --table-header-color:#666;
    --table-border-color:#e2e2e2;
    --table-borderinner-color:#eee;

    --drawer-menu-background:#084db1;
    --drawer-menu-foreground:#fff;
    --drawer-menu-title-color:#fff;
    --drawer-menu-toggle-color:#0969f5;
    --drawer-menu-toggle-color-hover:#4593ff;
    --drawer-menu-item-color:#fff;
    --drawer-menu-item-color-hover:#fff;
    --drawer-menu-item-color-active:#fff;    
    --drawer-menu-item-background:rgba(0,0,0,0);
    --drawer-menu-item-background-hover:rgba(255,255,255,0.1);    
    --drawer-menu-item-background-active:rgba(0,0,0,0.2);

    --code-background: #f5f5f5;
    --code-foreground: #737373;
    --code-border: #cecece;

    --badge-background:red;
    --badge-color:#fff;


    /*--loading-color: #0d6efd;*/
    
}

