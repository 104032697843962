.progressbar{
    display: table;
    /*background-color: #00000014;*/
    background-color: #ff0000f7;
    width: 100%;    
    height: 5px;
    margin: auto;
    position: relative;

    /*border-radius: 4px;*/
    overflow: hidden;
}/*.page.orders .listCards ul li .progress::before{    
    content: " ";
    position: absolute;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: #2b3238cf;
    transform: translateX(-50%);
}*/.progressbar .wrap{
    width: 100%;
    height: 100%;
}.progressbar .wrap span{
    display: table;
    height: 100%;
    /*background-color: #72818f69;*/
    background-color: #00d123;
    position: relative;
}.progressbar .wrap span label{
    font-size: 14px;
    padding: 2px 3px;
    color: #444;
    margin: 0px;
    display: inline-block;
    /*position: absolute;
    top: 0px;
    left: 0px;*/
}