.MenuMobile{
		display: block;

		z-index: 1001;
    	position: sticky !important;

		min-height: 58px;

	}.MenuMobile  ul{
		min-height: 58px;
		padding: 0px;
		margin: 0px;
		display: table;
		width: 100%;
		table-layout: fixed;
		position: relative;

	}.MenuMobile ul li{
		list-style: none;
		white-space: nowrap;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		cursor: pointer;
		/*overflow: hidden;*/
		position: relative;
		transition: 400ms;
	}.MenuMobile ul li *{
		cursor: pointer;
	}.MenuMobile ul li a{
		display: table;
		font-size: 14px;
		box-sizing: content-box;
		width: 100%;
		text-decoration: none;

		position: absolute !important;
		left:50%;
		top:50%;
		transform:translateX(-50%) translateY(-50%);

		border-collapse:separate;
        border-spacing:0px 4px;

        /*overflow: hidden;*/
	}.MenuMobile ul li a > i{
		/*font-size: 25px !important;*/
		transform: scale(1.5);
		/*height: 0px;*/
		display: inline-block;
	}



	.MenuMobile  ul li.loading a > i{	
	}.MenuMobile  ul li.loading a > i::before{

		display: inline-block;
		content: " " !important;
		background-position: center;
	    background-repeat: no-repeat;
	    background-size: 20px;
	    width: 20px;
	    /*height: 20px;*/
		/*content: "\f110" !important;*/
		/*background-image: url({package-url}/loading.gif);*/

	}.MenuMobile  ul li a > span{
		display: table-row;
		max-height: 0px;
		transition: 300ms;
		opacity: 0;
		font-size: 0px;
		
		margin: auto;
		position: relative;
		transform: scale(0);
	}.MenuMobile  ul > li > a >  label{
		position: absolute;
		top: -20%;
    	left: 50%;		
		background-color: red;
	    color: #fff;
	    width: 0px;
	    height: 0px;
	    padding: 2px 9px 1px ;
	    border-radius: 3px;
	    display: table;
	}.MenuMobile  ul > li > a >  label.transparent{
		background-color: transparent;	
	}.MenuMobile  ul > li.active > a i{
		transform: scale(1.7) translateY(0px);
	}.MenuMobile  ul > li.active > a > span{
		transform: scale(1) translateY(4px);
		font-size: 12px;
		opacity: 1;
		padding-top: 5px;
		max-height: 1000px;
		max-width: 1000px;
	}.MenuMobile ul > li > div{
		overflow: hidden;
		display: block;
		width: 100%;
		left: 0px;
		position: absolute;
		bottom: 100%;
		max-height: 0px !important;
		transition: 600ms;
		z-index: -1;
	}.MenuMobile ul > li.active > div{
		z-index: 1;
	}.MenuMobile  ul > li.active > div{
		overflow: visible;
		transition: 3000ms;
		max-height: 1000px !important;

	}.MenuMobile > span > ul > li ul{

		width: 100%;
		padding: 0px;
		margin: 0px;
		display: table;
		width: 100%;
		table-layout: fixed;

		overflow: hidden;
	}.MenuMobile ul li.active ul{
		overflow: visible;
		transform: translateY(0%);
		max-height: 1000px !important;
	}

	
	


	@keyframes menuBottomLoading {
	  0%   {
	  	/*opacity: 0.3;*/
	  	transform: rotate(0deg);
	  }
	  50%   {
	  	/*opacity: 1;*/
	  	transform: rotate(180deg);
	  }
	  100%   {
	  	/*opacity: 0.3;*/
	  	transform: rotate(360deg);
	  }
	  
	}


	.MenuMobile.-default {

	}.MenuMobile.-default ul {
		background-color: #8c8c8c ;
	}.MenuMobile.-default ul li a{
		text-decoration: none !important;
	}.MenuMobile.-default ul li a i{
		color: #0000007a;
	}.MenuMobile.-default ul li a i{		
		transition: 300ms;
	}.MenuMobile.-default ul li a:hover i{
		color: #ffffff59;
	}.MenuMobile.-default ul li a:active i{
		color: #fff;
	}.MenuMobile.-default ul li a span{
		color: #ffffff68;
	}.MenuMobile.-default ul li.active > a > i{
		color: #fff;
	}.MenuMobile.-default ul li.active > a > span{
		color: #fff;
	}.MenuMobile.-default   ul > li > a >  label i,
	.MenuMobile.-default   ul > li > a:hover >  label i{
		color: #fff;
	}







