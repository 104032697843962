.backtest .header1{
	border: 1px dashed #333;
    padding: 10px;
}.backtest .header1 label{
	display: block;
	color: #292d30;
	font-weight: bold;
	font-size: 12px;
	padding: 0px;
    margin: 0px;
}.backtest .header1 span{
	color: #aaa;
	font-size: 12px;
}.backtest .header1 span.gain{
	color: #1cb841;
}.backtest .header1 span.loss{
	color: #dc3545;
}

.backtest{
	margin: 0px;
    padding: 0px;
}.backtest .header2 label{
	color: #95aec5;
}.backtest .header2 span{
	color: #b1b7bd;
    font-size: 12px;
}.backtest .header2 span.gain{
	color: #1cb841;
}.backtest .header2 span.loss{
	color: #dc3545;
}.backtest .header2 small{
	display: block;
	color:#76838f;
}



.backtest_orders{
	display: block;
    height: 140px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 10px;
    background-color: #384148;
    padding: 5px;
    color: #677885;
}.backtest_orders ul{
	margin: 0px;
	padding: 0px;
}.backtest_orders ul li {
	color: #aaa;
	padding: 5px 0px;
	font-size: 11px;
}.backtest_orders ul li.start {
	/*color: #ffc800;*/
}.backtest_orders ul li.finish.gain {
	color: #19b53d;
}.backtest_orders ul li.finish.loss {
	color: #e70000;
}.backtest_orders ul li .-number{
	width: 10px;
    display: block;
    position: relative;
}.backtest_orders ul li .-number::after {
    content: " ";
    border-right: 1px solid #22272a;
    position: absolute;
    right: -5px;
    top: 0px;
    height: 100%;

}.backtest_orders ul li .-order{
	width: 25px;
    display: block;
}