.selectexchanges{
    /*padding: 10px 10px;*/
    /*margin-bottom: 15px;*/
    width: 100%;
}.selectexchanges > div{
        background-color: #56627063;
    border-radius: 5px;
    padding: 5px 10px;
    /* border: 1px solid #3b4044;*/

}.selectexchanges label{
    color: #22272c;
    padding: 10px;
    font-size: 12px;
    display: inline-table;
    margin: 0px;
}.selectexchanges .select{
    border: 1px solid #252a3000 !important;
    background-color: #252a3000 !important;
    padding: 5px 15px 5px 30px;
    font-size: 15px;
    color: #aaaaaa;
    background-position: calc(9% - 0.75rem) center;
}.selectexchanges .select:focus {
    box-shadow: none !important;
}.selectexchanges .select option[disabled] {
    color: #343a42 !important;
}